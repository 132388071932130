<template>
    <div>

        <CreateCustomGigTag ref="createGigTag" v-on:addedTag="newCreatedTag($event)"/> 
        <EditCustomGigTag ref="editGigTag" v-on:updatedTag="newEditedTag($event)" v-on:deletedTag="deletedTag($event)" /> 

        <InlineNotification Heading="Custom Tags" class="ml-4">
            <template slot="body">
                <p>Custom tags let you categorise and filter your gigs in a way that makes sense for your organisation.</p>
            </template>
            <template slot="cta">              
                <div v-show="hasTagAccess" @click="openCreateTagModal()" class="gp_cta cursor-pointer"><TagIcon class="ml-2 w-5 h-5" /><span class="mx-2">Add Custom Tag</span></div>
            </template>
        </InlineNotification>

        <div>
            <!-- <div class="ml-4 mt-12 flex-row lg:flex lg:space-x-32 space-y-6 lg:space-y-0">
                <div>
                    <div class="w-80 mt-4">
                        <t-input v-on:keyup.enter="loadData()" name="search" ref="search_name" placeholder="Search by name" v-model="search_phrase" />
                    </div>
                </div>
                <div>
                    <div class="w-92 mt-4">
                        <div class="flex items-center">
                            <span class="mr-4">Sort by</span>
                            <div class="w-96">
                                <TSelect class="trichselect thin w-full" v-model="sort_by" @change="updateSort()" 
                                    :options="[
                                        { value: '1', text: 'By name (A-Z)' },
                                        { value: '2', text: 'By name (Z-A)' },
                                    ]">
                                </TSelect>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <div v-show="(gig_tags.length || just_added_tags.length) && !isLoading" class="ml-4 flex flex-col mt-12 ">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="max-w-4xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200 text-left bg-white">
                                <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="relative px-6 py-3">
                                        <span class="sr-only">Edit</span>
                                    </th>
                                    <th scope="col" class="px-6 py-3 marker: text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Tag name
                                    </th>
                                    <th scope="col" class="px-6 py-3 hidden md:inline-block lg:block marker: text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Internal Description
                                    </th>
                                    <th scope="col" class="px-6 py-3 max-w-300px text-xs font-medium text-gray-500 uppercase tracking-wider"/>
                                </tr>
                                </thead>
                                <tbody v-show="just_added_tags.length" class="bg-green-100/50 divide-y divide-gray-200">
                                    <tr v-for="(new_tag, index) in just_added_tags" :key="index" :row="new_tag" class="relative">
                                        <td class="text-gp_pink-default-500 p-6">
                                            <span v-show="hasTagAccess" @click="openEditTagModal(new_tag, 'JUST_ADDED')" class="cursor-pointer">Edit</span>
                                        </td>
                                        <td class="p-6 space-x-3">
                                            <div class="flex space-x-3">
                                                <div :style="{ color: new_tag.icon_colour }">
                                                    <component :is="icons[new_tag.icon]" class="w-6 h-6"/>
                                                </div>
                                                <strong>{{ new_tag.name }}</strong>
                                            </div>
                                        </td> 
                                        <td class="p-6 hidden md:inline-block">
                                            <span v-if="new_tag.description">{{ new_tag.description.substring(0,35) }}</span>
                                            <span v-else>-</span>
                                            <span v-show="new_tag.description && new_tag.description.length > 35">...</span>
                                        </td> 
                                        <td>
                                            <span class="uppercase font-bold text-[10px] text-white py-1 px-3 mr-8 rounded-full bg-green-600 shadow">New</span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-show="gig_tags.length" class="bg-white divide-y divide-gray-200">
                                    <tr v-for="(gig_tag, index) in gig_tags" :key="index" :row="gig_tags" class="relative">
                                        <td class="text-gp_pink-default-500 p-6">
                                            <span v-show="hasTagAccess" @click="openEditTagModal(gig_tag, 'TAGS')" class="cursor-pointer">Edit</span>
                                        </td>
                                        <td class="p-6 space-x-3">
                                            <div class="flex space-x-3">
                                                <div :style="{ color: gig_tag.icon_colour }">
                                                    <component :is="icons[gig_tag.icon]" class="w-6 h-6"/>
                                                </div>
                                                <strong>{{ gig_tag.name }}</strong>
                                            </div>
                                        </td> 
                                        <td class="p-6 hidden md:inline-block">
                                            <span v-if="gig_tag.description">{{ gig_tag.description.substring(0,35) }}</span>
                                            <span v-else>-</span>
                                            <span v-show="gig_tag.description && gig_tag.description.length > 35">...</span>
                                        </td> 
                                    </tr>
                                </tbody>
                            </table>
                            <!-- <Pagination
                                @changePage="(n) => paginate_currPage = n"
                                :paginate_from="paginate_from"
                                :paginate_to="paginate_to"
                                :paginate_total="paginate_total"
                                :paginate_currPage="paginate_currPage"
                                :paginate_lastPage="paginate_lastPage"
                            ></Pagination> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="!gig_tags.length && !isLoading && !just_added_tags.length" class="max-w-4xl mt-44 flex items-center justify-center">
            <strong>No gig tags have been created on this account. Click the button in the heading above to begin.</strong>
        </div>
    </div>
</template>

<script>
import InlineNotification from '../../components/notifications/inline.vue'
// import Pagination from "@/components/elements/Pagination.vue";
// import { TSelect, TInput } from 'vue-tailwind/dist/components';
import EditCustomGigTag from '../../components/modal/venues/EditCustomGigTag.vue';
import CreateCustomGigTag from '../../components/modal/venues/CreateCustomGigTag.vue';
import { TagIcon } from "@vue-hero-icons/outline"
import client from '@/utils/client.js'
import  * as icons from "@vue-hero-icons/outline" 
import NProgress from 'nprogress';
import {mapState} from "vuex";

export default 
{
    name: "CustomTags",
    components: {
        InlineNotification, EditCustomGigTag, CreateCustomGigTag, TagIcon,
        // Pagination, TSelect, TInput,
    },
    data() {
        return {
            isLoading: false,
            editedFrom: '',
            icons: icons,
            just_added_tags: [],
            gig_tags: [],
            search_phrase: '',
            sort_by: '',
            paginate_from: null,
            paginate_to: null,
            paginate_total: null,
            paginate_currPage: 1,
            paginate_lastPage: null,
        }
    },
    mounted(){
        this.loadData();
    },
    computed: {
        ...mapState({
			userRoles: (state) => state.user.rolesPermissionsSlugs,
		}),
        hasTagAccess()
        {
            if (this.userRoles && this.userRoles.length > 0) {
                return this.userRoles[0] == 'full-access';
            } else {
                return null; 
            }
        }
    },
    methods:
    {
        async loadData(){

            this.isLoading = true;
            NProgress.start();

            await client.get('custom-tags')
                .then((response) => {
                    this.gig_tags = response.data.data;
                })
                .finally(() => {
                    NProgress.done();
                    this.isLoading = false;
                })
        },
        openCreateTagModal()
        {
            this.$refs.createGigTag.modalOpen = true;
        },
        openEditTagModal(value, editedFrom)
        {
            this.editedFrom = editedFrom
            this.$refs.editGigTag.updateTag(value);
            this.$refs.editGigTag.modalOpen = true;
        },
        newCreatedTag($tag)
        {
            this.just_added_tags.push($tag);
        },
        newEditedTag($tag)
        {
            
            if (this.editedFrom === 'JUST_ADDED') {
                let index = this.just_added_tags.findIndex((f) => f.id === $tag.id);
                this.$set(this.just_added_tags, index, $tag);
            } else {
                let index = this.gig_tags.findIndex((f) => f.id === $tag.id);
                this.$set(this.gig_tags, index, $tag);
            }
        },
        deletedTag($tag)
        {
            if (this.editedFrom === 'JUST_ADDED') {
                let index = this.just_added_tags.findIndex((f) => f.id === $tag.id);
                this.just_added_tags.splice(index, 1);
            } else {
                let index = this.gig_tags.findIndex((f) => f.id === $tag.id);
                this.gig_tags.splice(index, 1);
            }
        }
    }

}
</script>